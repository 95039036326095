import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let TaloLogo = (props) => (
	<SvgIcon {...props} viewBox={"0 0 338 94"}>
		<title>Talo</title>

		<g>
			<path
				d="M64.71,70.67c0,11.16-9.05,20.21-20.21,20.21s-20.21-9.05-20.21-20.21,9.05-20.21,20.21-20.21,20.21,9.05,20.21,20.21"
				fill="#1776ff"
			/>
			<path
				d="M66.1,42.74H21.66c-11.16,0-20.21-9.05-20.21-20.21S10.5,2.32,21.66,2.32h44.43c11.16,0,20.21,9.05,20.21,20.21s-9.05,20.21-20.21,20.21"
				fill="#1776ff"
			/>
		</g>
		<g>
			<path
				d="M149.42,37.28v-16.31h-16.18V2.32h-16.87v13.03c0,3.7-2.06,5.62-5.75,5.62h-6.32v16.31h11.11v31.53c0,14.39,8.36,22.07,24.4,22.07h9.6v-16.31h-6.99c-5.76,0-9.19-3.3-9.19-8.78v-28.52h16.18Z"
				fill="#11183d"
			/>
			<path d="M232.59,2.32v88.56h17.82V2.32h-17.82Z" fill="#11183d" />
			<path
				d="M298.26,19.32c-21.38,0-37.55,15.77-37.55,36.59s16.17,36.61,37.55,36.61,37.56-15.77,37.56-36.61-16.17-36.59-37.56-36.59ZM298.26,75.26c-10.96,0-19.18-8.36-19.18-19.34s8.22-19.33,19.18-19.33,19.2,8.36,19.2,19.33-8.09,19.34-19.2,19.34Z"
				fill="#11183d"
			/>
			<path
				d="M216.88,69.99c5.33-12.19,4.37-34.54-7.81-44.12-14.73-10.81-37.32-7.92-50.37,6.41l-.82.9,12.84,10.42.73-.7c2.47-2.35,5.51-4.31,8.54-5.5,3.13-1.25,6.33-1.7,9.8-1.37,4.26.25,7.6,1.74,9.96,4.41,2.53,2.9,3.88,7.23,3.87,12.34-12.13-8.15-30.25-10.35-41.73,1.47-7.21,7.16-8.07,18.72-2.05,27.51,3.9,5.57,9.58,9.13,16.39,10.33,1.73.31,3.5.45,5.31.45,7.14,0,14.76-2.31,21.43-6.54.06,1.12.09,2.27.11,3.44v1.14h17.6l-.02-1.16c-.07-5.65-.73-10.66-2.03-15.27M183.87,76.96c-.99.17-1.98.25-2.92.25-2.15,0-4.13-.43-5.64-1.27-2.15-1.08-3.88-3.63-4.29-6.37-.2-1.36-.25-3.91,1.83-5.91,2.32-2.64,5.97-3.74,9.73-3.74,2.84,0,5.75.63,8.17,1.7,3.23,1.42,5.77,3.62,7.72,6.74-3.71,4.57-9,7.69-14.61,8.6Z"
				fill="#11183d"
			/>
		</g>
	</SvgIcon>
);

TaloLogo = memo(TaloLogo);
TaloLogo.displayName = "TaloLogo";
TaloLogo.muiName = "TaloLogo";

export default TaloLogo;
